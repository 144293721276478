export const animationData = [
    {
        slug: "max",
        name: "Max Cattell",
        headshot: "/student-images/animation/headshots/max.jpg",
    },
    {
        slug: "amy",
        name: "Amy Pesendorfer",
        headshot: "/student-images/animation/headshots/amy.jpg",
    }
]